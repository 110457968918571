<div *ngLet="playlistPage$ | async as playlistPage">
    <div class="table-sort mt-2">
        <button class="playlist-add btn btn-danger" (click)="openCreatePlaylistModal()">
            New playlist
            <fa-icon [icon]="faPlus" />
        </button>
        <div class="sort">
            <!--  <app-sort></app-sort>
            <app-view></app-view> -->
        </div>
    </div>

    @if (playlistPage !== null) {
        <div class="mt-2 {{ (isGridView$ | async) ? 'grid-4' : 'list-view' }}">
            @for (playlist of playlistPage?.results; track playlist) {
                <app-playlist-item [playlist]="playlist" />
            }
        </div>
    }
    @if (playlistPage?.results?.length === 0) {
        <div class="mt-2 italic">
            You do not have any saved playlists.
        </div>
    }
    @if (playlistPage !== null) {
        <div>
            <app-pagination [totalItems]="playlistPage?.count" [pageSize]="pageSize" />
        </div>
    }

    @if (playlistPage === null) {
        <div class="flex-center mt-5">
            <app-spinner />
        </div>
    }
</div>

<app-create-playlist-modal />
