@if (uploadedLectures$ | async; as lecturePage) {
    <div class="mt-2 grid-4">
        @if (isAdmin()) {
            <div class="card" (click)="goToUpload()">
                <div class="content">
                    <fa-icon [icon]="faPlusCircle" />
                    <p class="bold">Upload new video</p>
                </div>
            </div>
        }

        @for (lecture of lecturePage.results | slice:0: !!isAdmin() ? 7 : 8; track lecture.id) {
            <app-video-item [lecture]="lecture" [canDelete]="true" />
        }
    </div>

    <app-pagination [totalItems]="lecturePage.count" [pageSize]="pageSize" />
} @else {
    <app-spinner />
}
