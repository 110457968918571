import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AppRoutes } from 'src/app/routing/types/app-route';
import { ImageDirective } from '../../../../@common/DOM/image.directive';
import { UserProfile } from '../../../../@domain/user/entities/user-profile.interface';

@Component({
    selector: 'app-profile-header',
    templateUrl: './profile-header.component.html',
    styleUrls: [ './profile-header.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ImageDirective,
        AsyncPipe,
        RouterLink
    ],
})
export class ProfileHeaderComponent {
    public profile = input.required<UserProfile>();
    protected readonly AppRoutes = AppRoutes;
}
