import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { SlugService } from '../../@domain/slug/application/slug.service';
import { SlugType } from '../../@domain/slug/entities/slug-type.enum';
import { GenericRoute } from '../routes';
import { AppRoutes } from '../types/app-route';

export const slugGuardFn: CanActivateFn = async (route) => {
    const slugService = inject(SlugService);
    const router = inject(Router);

    const slug = route.params.slug;
    const slugType = await firstValueFrom(slugService.getSlugType(slug));

    if (slugType === SlugType.Event) {
        return router.createUrlTree([ '/' + AppRoutes.Events + '/' + slug ]);
    }

    if (slugType === SlugType.Lecture) {
        return router.createUrlTree([ '/' + AppRoutes.Videos + '/' + slug ]);
    }

    if (slugType === SlugType.Author) {
        return router.createUrlTree([ '/' + AppRoutes.Authors + '/' + slug ]);
    }

    return router.createUrlTree([ '/' + GenericRoute.NotFound ]);
};
