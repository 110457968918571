<div class="banner">
    <h1>Exchange ideas. Share knowledge.</h1>
</div>

<div class="sections-container">
    <section>
        <div class="container">
            <app-section-header headline="Categories" [link]="AppRoutes.Categories" buttonText="View all" />
            <app-categories />
        </div>
    </section>
    @if (isAuthenticated()) {
        <section class="bg-light">
            <div class="container">
                <app-section-header
                        headline="Welcome back {{ user()?.user?.first_name }}, do you want to continue where you left?" />
                <app-history />
            </div>
        </section>
    }
    <section>
        <div class="container">
            <app-section-header headline="Latest Lectures" [link]="AppRoutes.Videos" buttonText="View all" />
            <app-latest-lectures />
        </div>
    </section>

    @defer (on viewport; on idle) {
        <section>
            <div class="container">
                <app-section-header headline="Trending" [link]="AppRoutes.Videos" buttonText="View all"
                                    [linkParams]="{ sort : 'starred'}" />
                <app-trending-lectures />
            </div>
        </section>
    } @placeholder {
        <div class="section-placeholder"></div>
    }

    @defer (on viewport; on idle) {
        <section class="bg-light">
            <div class="container">
                <app-section-header headline="Latest Events" [link]="AppRoutes.Events" buttonText="View all" />
                <app-events-section />
            </div>
        </section>
    } @placeholder {
        <div class="section-placeholder"></div>
    }
</div>


@defer (on viewport; on idle) {
    <div class="container last-section">
        <div>
            <app-section-header headline="Latest News" [link]="AppRoutes.News" buttonText="All News" isSubHeadline />
            <app-news />
        </div>
        <div>
            <app-section-header headline="Upcoming Events" isSubHeadline />
            <app-upcoming-events-section />
            <!-- <angular-twitter-timeline
            [data]="{ sourceType: 'profile', url: 'videolectures' }"
            [opts]="{ tweetLimit: 5, height: 500 }"
          ></angular-twitter-timeline> commented out has 5 gh stars? -->
        </div>
    </div>
} @placeholder {
    <div class="section-placeholder"></div>
}

<app-modal [isSmall]="true" [tag]="Modal.WelcomeNotice">
    <app-modal-body class="text-center">
        <div class="body px-1">
            <div class="close">
                <fa-icon [icon]="faTimes" size="lg" (click)="onCloseModal()"></fa-icon>
            </div>
            <h2 class="text-center mb-1">
                DISCLAIMER:
            </h2>
            <div class="message">
                <p class="text-center">
                    "We’re back, but we’re still in the process of developing our new website. We’d love to hear your
                    suggestions
                    for changes, or if some of your favorite talks are not available!
                </p>
                <br>
                <p class="text-center">
                    We’ll be making daily improvements over the next couple of weeks, so stay tuned for an even better
                    browsing
                    experience. 🚀
                </p>
                <br>
                <p class="text-center">
                    Thank you for being a part of our journey. We can’t wait to make your visits here more enjoyable
                    than
                    ever!
                </p>
                <br>
                <p class="text-center">
                    Contact us at <a href="mailto:info@videolectures.net">info&#64;videolectures.net</a>
                </p>
            </div>
            <button class="btn btn-primary mt-3 mb-1" (click)="onCloseModal()">Continue</button>

            <div class="mt-1 text-center">
                <a [routerLink]="AppRoutes.Home">
                    <picture>
                        <source
                                srcset="../../../assets/vln_default.svg"
                                media="(min-width: 851px)"
                        />
                        <img src="../../../assets/vln_tiny.svg" alt="logo" style="height: 2rem" />
                    </picture>
                </a>
            </div>
        </div>
    </app-modal-body>
</app-modal>
