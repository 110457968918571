import { AsyncPipe, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ImageDirective } from '../../../../@common/DOM/image.directive';
import { UploadService } from '../../../../@domain/upload/application/upload.service';
import { VideoService } from '../../../../@domain/video/application/video.service';
import { Video } from '../../../../@domain/video/entities/video.interface';
import { AppRoutes } from '../../../../routing/types/app-route';
import { BadgeComponent } from '../../../global/badge/badge.component';
import { SpinnerComponent } from '../../../global/spinner/spinner.component';
import { RelatedCategoriesComponent } from '../../profile/lecturer-header/related-categories/related-categories.component';

@Component({
    selector: 'app-upload-form4',
    templateUrl: './upload-form4.component.html',
    styleUrls: [ './upload-form4.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        BadgeComponent,
        RelatedCategoriesComponent,
        ImageDirective,
        SpinnerComponent,
        DatePipe,
        AsyncPipe
    ],
})
export class UploadForm4Component {
    private lectureService = inject(VideoService);
    private router = inject(Router);
    private uploadService = inject(UploadService);

    public lecture$: Observable<Video> = this.lectureService.getVideo(this.uploadService.lectureData().slug!);

    onClickBack() {
        this.uploadService.previousStep();
    }

    onClickNext(lecture: Video): void {
        if (lecture.parts[0]?.presentations?.length) {
            this.router.navigate([ `/${ AppRoutes.Videos }/${ lecture.slug }/${ lecture.parts[0]?.presentations[0].language }` ]);
        } else {
            this.uploadService.reset();
            this.router.navigate([ `/${ AppRoutes.Profile }` ]);
        }
    }

}
