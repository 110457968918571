import { ChangeDetectionStrategy, Component, inject, input, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, Router, RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { ModalService } from './@domain/modal/modal.service';
import { FooterComponent } from './@ui/footer/footer.component';
import { HeaderComponent } from './@ui/header/header.component';

@Component({
    selector: 'app-root',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        HeaderComponent,
        RouterOutlet,
        FooterComponent,
    ],
    styles: `
        :host {
            display: flex;
            flex-direction: column;
            justify-content: center;

            main {
                padding-top: 4.75rem;
                min-height: 100dvh;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .content {
                flex: 1;
            }
        }
    `,
    template: `
        <app-header [searchQuery]="query()"/>
        <main class="content">
            <router-outlet/>
        </main>
        <app-footer/>
    `
})
export class AppComponent implements OnInit {
    private modalService = inject(ModalService);
    private router = inject(Router);

    public query = input<string>()

    ngOnInit(): void {
        document.body.addEventListener('click', (e: Event) => {
            this.modalService.checkModalWindow(e);
        });

        console.debug('Version:', environment.version);

        this.router.events.subscribe(evt => {
            if (evt instanceof NavigationEnd) {
                this.modalService.close()
            }

            if (evt instanceof NavigationError && evt.error.message.includes('dynamically imported')) {
                window.location.assign(evt.url);
            }
        });
    }
}
