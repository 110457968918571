@if (authorPage$ | async; as authorPage) {
    <div class="mt-2">
        @if (authorPage?.results?.length) {
            <div class="list-view">
                @for (author of authorPage?.results; track author; let even = $even) {
                    <app-author-item
                            [class.bg-light]="even" [author]="author" [following]="true" />
                }
            </div>
        } @else {
            <p class="italic mt-2 no-results">You do not follow any authors.</p>
        }
        <app-pagination [totalItems]="authorPage?.count" [pageSize]="pageSize" />
    </div>
} @else {
    <app-spinner />
}

