import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { map, Observable } from 'rxjs';
import { VideoService } from 'src/app/@domain/video/application/video.service';
import { Video } from '../../../../../@domain/video/entities/video.interface';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { VideoItemComponent } from '../../videos/video-item/video-item.component';

@Component({
    selector: 'app-latest-lectures',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ VideoItemComponent, SpinnerComponent, AsyncPipe ],
    styles: `
        :host {
            display: block;
            width: 100%;
            min-height: 300px;
        }
    `,
    template: `
        @if (lectures$ | async; as lectures) {
          <div class="grid-4">
            @for (lecture of lectures; track lecture) {
              <app-video-item [lecture]="lecture"
                [gridView]="false" priorityImageLoading/>
            }
          </div>
        } @else {
          <app-spinner/>
        }
        `
})
export class LatestLecturesComponent {
    lectures$: Observable<Video[]>
    faArrowRight = faArrowRight;

    constructor(private lecturesService: VideoService) {
        this.lectures$ = this.lecturesService.getLatestVideos().pipe(
            map(({ results }) => results ?? [])
        );
    }

}
