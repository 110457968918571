import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { UserService } from '../../../../@domain/user/application/user.service';
import { AppRoutes } from '../../../../routing/types/app-route';
import { DropdownComponent } from '../../../global/sort/dropdown.component';
import { ProfileHeaderComponent } from '../profile-header/profile-header.component';

@Component({
    selector: 'app-profile-dashboard',
    templateUrl: './profile-dashboard.component.html',
    styleUrls: [ './profile-dashboard.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ RouterLink, DropdownComponent, RouterOutlet, AsyncPipe, ProfileHeaderComponent ],
})
export class ProfileDashboardComponent {
    private userService = inject(UserService);
    private route = inject(ActivatedRoute);
    private router = inject(Router);

    public user = this.userService.userProfile;
    public AppRoutes = AppRoutes;
    public navItems = computed(() => {
        const userItems = [
            { name: 'Playlists', value: 'playlists' },
            { name: 'Bookmarks', value: 'bookmarks' },
            { name: 'Following', value: 'following' },
        ]

        return this.userService.isAdmin()
            ? [
                { name: 'Videos', value: 'videos' },
                ...userItems,
            ]
            : userItems;
    });

    public changeTab(tab: string) {
        this.router.navigate([ `/${ AppRoutes.Profile }/${ tab }` ]);
    }

    public get currentTab(): string {
        const tab = this.navItems().find(i => i.value === this.route.firstChild!.routeConfig!.path!)?.name;

        if (!tab) {
            this.router.navigate([ '/profile' ]);
            return '';
        }

        return tab;
    }

}
