@if (user(); as profile) {
    <app-profile-header [profile]="profile" />
}

<section class="container">
    <div class="tabs-container">
        <a class="btn btn-edit rounded-full" routerLink="/{{ AppRoutes.Profile }}/edit">Edit profile</a>
        <div class="tabs">
            @for (item of navItems(); track item) {
                <a [class.active]="item.name === currentTab" [routerLink]="item.value">
                    {{ item.name }}
                </a>
            }
        </div>

        <app-dropdown [defaultName]="currentTab" [items]="navItems()" (onClick)="changeTab($event)" />
    </div>

    <router-outlet />
</section>
