import { Component, inject, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { Modal } from '../../../@domain/modal/modal.enum';
import { ModalService } from '../../../@domain/modal/modal.service';
import { SlugService } from '../../../@domain/slug/application/slug.service';
import { SlugType } from '../../../@domain/slug/entities/slug-type.enum';
import { LocalStorageService } from '../../../@domain/storage/application/local-storage.service';
import { ModalBodyComponent } from '../../global/modal/modal-body/modal-body.component';
import { ModalFooterComponent } from '../../global/modal/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from '../../global/modal/modal-header/modal-header.component';
import { ModalComponent } from '../../global/modal/modal.component';

@Component({
    selector: 'app-load-lecture-modal',
    standalone: true,
    imports: [
        ModalComponent,
        ModalHeaderComponent,
        ModalBodyComponent,
        ModalFooterComponent,
        FormsModule
    ],
    styles: `
        button.import {
            display: block;
            margin-left: auto;
        }
    `,
    template: `
        <app-modal [tag]="Modal.LoadVideo">
            <app-modal-header modalTitle="Import from existing video" (onClose)="closeModal()"/>

            <app-modal-body>
                <div class="form-control">
                    <label>Existing video slug</label>
                    <input type="text" [(ngModel)]="slug"/>
                </div>
            </app-modal-body>

            <app-modal-footer>
                <button class="import btn btn-danger" (click)="loadLecture()">Import</button>
            </app-modal-footer>
        </app-modal>
    `
})
export class LoadLectureModalComponent {
    private storageService = inject(LocalStorageService);
    private slugService = inject(SlugService);
    private modalService = inject(ModalService);
    private toastr = inject(ToastrService);

    public onImport = output<string>();
    public slug = signal(this.storageService.getLastCreatedVideoSlug() ?? '');
    public Modal = Modal;

    public async loadLecture() {
        if (await this.validateSlug(this.slug())) {
            this.onImport.emit(this.slug());
            this.storageService.setLastCreatedVideoSlug(this.slug());
            this.closeModal();
        }
    }

    private async validateSlug(slug: string) {
        const type = await firstValueFrom(this.slugService.getSlugType(slug))

        if (!type) {
            this.toastr.error('Slug not found');
            return false;
        }

        if (type !== SlugType.Lecture) {
            this.toastr.error(`Slug is not a lecture! (${ type })`);
            return false;
        }

        return true;
    }

    public closeModal() {
        this.modalService.close();
    }

}
