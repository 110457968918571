import { IMAGE_CONFIG } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, UrlSerializer, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { provideMarkdown } from 'ngx-markdown';
import { provideMatomo, withRouter } from 'ngx-matomo-client';
import { provideToastr } from 'ngx-toastr';
import { AuthInterceptor } from './app/@common/http/interceptor/auth.interceptor';
import { RollbarErrorHandler } from './app/@common/log/rollbar.error-handler';
import { Configuration } from './app/@generated';
import { AppComponent } from './app/app.component';
import { ROUTES } from './app/routing/routes';
import { TrailingSlashUrlSerializer } from './app/routing/util/trailing-slash-url-serializer';
import { environment } from './environments/environment';

if (environment.environment === 'production') {
    enableProdMode();
}

function bootstrap() {
    bootstrapApplication(AppComponent, {
        providers: [
            importProvidersFrom(
                ConfirmationPopoverModule.forRoot({
                    confirmButtonType: 'danger',
                })
            ),
            provideHttpClient(withInterceptors([ AuthInterceptor ])),
            provideRouter(
                ROUTES,
                withComponentInputBinding(),
                withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
            ),
            provideMatomo({
                siteId: 1,
                disabled: environment.environment === 'local',
                trackerUrl: environment.matomoUrl
            }, withRouter()),
            provideToastr(),
            provideMarkdown(),
            provideAnimations(),
            { provide: UrlSerializer, useClass: TrailingSlashUrlSerializer },
            { provide: ErrorHandler, useClass: RollbarErrorHandler },
            {
                provide: Configuration,
                useValue: new Configuration({ basePath: environment.apiUrl })
            },
            {
                provide: IMAGE_CONFIG,
                useValue: {
                    disableImageSizeWarning: true,
                }
            },
        ]
    }).catch(err => console.error(err));
}

if (document.readyState === 'complete') {
    bootstrap();
} else {
    document.addEventListener('DOMContentLoaded', bootstrap);
}
