import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { map, Observable } from 'rxjs';
import { CategoryService } from 'src/app/@domain/category/application/category.service';
import { SwiperOptions } from 'swiper';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { ImageDirective } from '../../../../../@common/DOM/image.directive';
import { Category } from '../../../../../@domain/category/entities/category.interface';
import { AppRoutes } from '../../../../../routing/types/app-route';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: [ './categories.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SwiperModule,
        RouterLink,
        ImageDirective,
        FaIconComponent,
        SpinnerComponent,
        AsyncPipe
    ],
})
export class CategoriesComponent {
    @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
    categories$?: Observable<Category[]>;
    AppRoutes = AppRoutes;
    faArrowRight = faArrowRight;
    faArrowLeft = faArrowLeft;

    constructor(private categoryService: CategoryService) {
        this.categories$ = this.categoryService.getTopCategories()
            .pipe(
                map(categories => categories.filter(category => !!category.image)),
            );
    }

    config: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 45,
        lazy: true,
        breakpoints: {
            320: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            450: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            720: {
                slidesPerView: 4,
                spaceBetween: 40,
            },
            900: {
                slidesPerView: 5,
                spaceBetween: 40,
            },
            1120: {
                slidesPerView: 6,
                spaceBetween: 40,
            },
        },
    };

    slideNext() {
        this.swiper?.swiperRef.slideNext(100);
    }

    slidePrev() {
        this.swiper?.swiperRef.slidePrev(100);
    }

    trackByFn(index: number, item: Category): number {
        return item.id;
    }

}
