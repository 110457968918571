@if (isGridView$ | async) {
    <div class="grid-view">
        <a class="card" routerLink="/{{AppRoutes.Playlists}}/{{playlist.id}}">
            <div class="card-header">
                <img [src]="playlist.image || 'assets/download.png'" alt="playlist-img" />
            </div>
            <div class="card-body">
                <h4>{{ playlist.title }}</h4>
                <div class="py-1 pb-1"></div>
            </div>
            <div class="bottom-content">
                <p>{{ playlist.lectures.length }} Videos</p>
                <p>Updated {{ playlist.published }}</p>
            </div>
        </a>
    </div>
} @else {
    <a class="list-view" routerLink="/{{AppRoutes.Playlists}}/{{playlist.id}}">
        <img [src]="playlist.image || 'assets/download.png'" alt="video-img" loading="lazy" />
        <div class="right">
            <div class="info">
                <strong>{{ playlist.title }}</strong>
            </div>
            <div class="numbers">
                @if (playlist.published) {
                    <p>Updated {{ playlist.published }}</p>
                }
                <p>{{ playlist.lectures.length }} Videos</p>
            </div>
        </div>
    </a>
}

