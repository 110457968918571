import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../../@domain/user/application/user.service';
import { AppRoutes } from '../types/app-route';

export const adminGuardFn: CanActivateFn = () => {
    const userService = inject(UserService);
    const router = inject(Router);

    return !!userService.isAdmin() || router.createUrlTree([ '/' + AppRoutes.Login ]);
}
