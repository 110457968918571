import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, ElementRef, inject, input, signal, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { NgLetModule } from 'ng-let';
import { filter, map, Observable } from 'rxjs';
import { UserService } from 'src/app/@domain/user/application/user.service';
import { onFirstClick } from '../../@common/DOM/close-on-first-click.function';
import { ImageDirective } from '../../@common/DOM/image.directive';
import { AppRoutes } from '../../routing/types/app-route';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: [ './header.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgLetModule,
        RouterLink,
        ImageDirective,
        FormsModule,
        FaIconComponent,
        AsyncPipe
    ],
})
export class HeaderComponent {
    private userService = inject(UserService);
    private router = inject(Router);
    private route = inject(ActivatedRoute);

    public searchQuery = input.required<string | undefined>();
    public searchBar = viewChild.required<ElementRef<HTMLInputElement>>('searchBar');
    public userProfile = this.userService.userProfile;
    public isAuthenticated = this.userService.isAuthenticated;
    public isAdmin = this.userService.isAdmin;
    public redBackground$: Observable<boolean>;
    public isMenuOpen = signal(false);
    public isSignedInMenuOpen = signal(false);
    public faSearch = faSearch;
    public AppRoutes = AppRoutes;

    constructor() {
        this.redBackground$ = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map((event: any) => event.url === '/' || event.url === '/lecture-agreement')
            );

        effect(() => {
            if (this.isSignedInMenuOpen()) {
                onFirstClick(() => this.isSignedInMenuOpen.set(false))
            }
        })
    }

    public onLogout() {
        this.userService.logout();
    }

    public onSubmit(): void {
        const query = this.searchBar().nativeElement.value;

        if (!query) return;
        this.searchBar().nativeElement.blur();

        this.router.navigate([ AppRoutes.Search ], {
            queryParams: { query },
            relativeTo: this.route
        });
    }

    public toggleProfileMenu(): void {
        this.isSignedInMenuOpen.set(!this.isSignedInMenuOpen());
    }

}
