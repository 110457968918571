<div class="dropdown">
    <button class="dropdown-btn btn" (click)="toggleDropdown()">
        <span class="label">{{ getLabel() }}</span>
        <fa-icon [icon]="faChevronDown" />
    </button>
    <div class="menu" [class.open]="isOpen()">
        <div class="search">
            <fa-icon [icon]="faSearch" />
            <input [placeholder]="searchPlaceholder()" [ngModel]="search()" (ngModelChange)="search.set($event)" />
        </div>
        <div class="options">
            @if (items() !== null) {
                @for (item of searchedItems() | slice:0 : 50; track item) {
                    <div class="dropdown-item"
                         [class.selected]="item.checked" (click)="toggleItem(item)">
                        @if (item.checked) {
                            <fa-icon [icon]="faCheck" />
                        }
                        <span [class.active]="item.checked">{{ item.text }}</span>
                    </div>
                }
            } @else {
                <app-spinner />
            }
        </div>

        @if (selectedItems()?.length) {
            <div class="clear" (click)="clear()">
                Clear all ({{ selectedItems()!.length }})
            </div>
        }

        <ng-content />
    </div>

    <app-error [error]="error()" />
</div>


