@if (categories$ | async; as categories) {
    <div class="swiper">
        <swiper [config]="config" #swiper>
            @for (category of categories; track trackByFn($index, category)) {
                <ng-template swiperSlide>
                    <a class="slide" [title]="category.name" [routerLink]="[AppRoutes.Categories, category.id]"
                       [queryParams]="{ sort: 'published' }">
                        <img class="swiper--lower" [src]="category.image" alt="{{ category.name }} image"
                             loading="lazy" />
                        <p class="text-center">{{ category.name }}</p>
                    </a>
                </ng-template>
            }
        </swiper>
        <fa-icon class="swiper-button-prev" [icon]="faArrowLeft" (click)="slidePrev()" />
        <fa-icon class="swiper-button-next" [icon]="faArrowRight" (click)="slideNext()" />
    </div>
} @else {
    <app-spinner />
}
