import { AdminRoute } from './types/admin-route';

export const ADMIN_ROUTES = [
    {
        path: AdminRoute.Home,
    },
    {
        path: AdminRoute.Videos,
    }
]
