import { Routes } from '@angular/router';
import { ADMIN_ROUTES } from './admin.routes';
import { APP_ROUTES } from './app.routes';
import { adminGuardFn } from './guards/admin.guard';
import { authGuardFn } from './guards/auth.guard';
import { AdminRoute } from './types/admin-route';

export enum GenericRoute {
    NotFound = 'not-found',
    Unauthorized = 'unauthorized',
}

export const ROUTES: Routes = [
    {
        path: GenericRoute.NotFound,
        loadComponent: () => import('../@page/app/not-found/not-found.page')
    },
    {
        path: GenericRoute.Unauthorized,
        loadComponent: () => import('../@page/app/unauthorized/unauthorized.page')
    },
    {
        path: AdminRoute.Admin,
        canActivate: [ authGuardFn, adminGuardFn ],
        loadChildren: () => ADMIN_ROUTES
    },
    ...APP_ROUTES,
    {
        path: '**',
        redirectTo: GenericRoute.NotFound
    }
];
