import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgLetModule } from 'ng-let';
import { Observable } from 'rxjs';
import { ItemsViewService } from 'src/app/@domain/items-view/items-view.service';
import { PlaylistService } from 'src/app/@domain/playlist/application/playlist.service';
import { PagedComponent } from '../../../../../../@common/abstract/paged-component.abstract';
import { Page } from '../../../../../../@common/entities/page.interface';
import { loadingMap } from '../../../../../../@common/rxjs/loading-map';
import { Modal } from '../../../../../../@domain/modal/modal.enum';
import { ModalService } from '../../../../../../@domain/modal/modal.service';
import { Playlist } from '../../../../../../@domain/playlist/entities/playlist.interface';
import { ErrorComponent } from '../../../../../global/error/error.component';
import { ModalBodyComponent } from '../../../../../global/modal/modal-body/modal-body.component';
import { ModalFooterComponent } from '../../../../../global/modal/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from '../../../../../global/modal/modal-header/modal-header.component';
import { ModalComponent } from '../../../../../global/modal/modal.component';
import { PaginationComponent } from '../../../../../global/pagination/pagination.component';
import { SpinnerComponent } from '../../../../../global/spinner/spinner.component';
import { CreateAuthorModalComponent } from '../../../../../modals/create-author-modal/create-author-modal.component';
import { CreatePlaylistModalComponent } from '../../../../../modals/create-playlist-modal/create-playlist-modal.component';
import { PlaylistItemComponent } from '../../../playlist-item/playlist-item/playlist-item.component';

@Component({
    selector: 'app-user-playlist',
    templateUrl: './user-playlist.component.html',
    styleUrls: [ './user-playlist.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ NgLetModule, FaIconComponent, PlaylistItemComponent, PaginationComponent, SpinnerComponent, ModalComponent, ModalHeaderComponent, ModalBodyComponent, FormsModule, ErrorComponent, ModalFooterComponent, AsyncPipe, CreateAuthorModalComponent, CreatePlaylistModalComponent ]
})
export class UserPlaylistComponent extends PagedComponent {
    isGridView$?: Observable<boolean>;
    playlistPage$?: Observable<Page<Playlist> | undefined>;
    pageSize = 8;
    faPlus = faPlus;

    constructor(
        private playlistService: PlaylistService,
        private itemsViewService: ItemsViewService,
        private modalService: ModalService,
        route: ActivatedRoute,
        router: Router
    ) {
        super(route, router);

        this.isGridView$ = this.itemsViewService.onChangeView();
        this.playlistPage$ = this.observeParameters('page').pipe(
            loadingMap(({ page }) => this.playlistService.getPlaylists(this.pageSize, this.offset(page)))
        );
    }

    openCreatePlaylistModal(): void {
        this.modalService.open(Modal.CreatePlaylist);
    }

}
