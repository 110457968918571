@if (historyPage$ | async; as historyPage) {
    <div class="grid-4">
        @for (lecture of historyPage.results | slice: 0 :3; track lecture.lecture.id) {
            <app-video-item [lecture]="lecture.lecture!" [gridView]="false" />
        }

        <a class="card-history" [routerLink]="AppRoutes.History">
            <div class="card-header"></div>
            <div class="card-body">
                <h4>Your watch history</h4>
            </div>
        </a>
    </div>
} @else {
    <app-spinner />
}
